.socialMediaContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.socialMediaDrawer {
  display: flex;
}

.socialMediasIcons {
  width: 1.5rem;
  margin: 0.2rem;
}
