.gridList {
  flex-wrap: wrap;
  transform: translateZ(0);
}
.title {
  color: white;
}
.titleBar {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(0, 0, 0, 0) 100%
  );
}
