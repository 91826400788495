.header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.headerLogo {
  max-width: 15rem;
}
.burgerMenu {
  color: #48e10e;
}
.selectedMenu {
  border-bottom: 0.2rem solid #2c7b2c;
  width: fit-content;
}
.headerLinks {
  display: flex;
  & a {
    margin: 1rem;
    text-decoration: none;
    color: #48e10e;
    font-weight: 600;
    font-size: 1.15rem;
  }
}
.languageBtn {
  color: #48e10e !important;
  font-weight: 900 !important;
  border-color: yellow !important;
  border-radius: 50% !important;
}
