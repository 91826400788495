.contactUsContainer {
  text-align: center;
  p {
    font-size: 1.1rem;
  }
}
.contactUsInfo {
  text-align: justify;
  & > p {
    margin: 1rem 0;
    @media screen and (min-width: 600px) {
      max-width: 40rem;
      margin: 1rem auto;
    }
  }
}
.contactUsInfoText {
  & > a {
    color: #136600;
    text-decoration: none;
    border-bottom: 1px #136600 solid;
  }
}
.contactUsForm {
  @media screen and (min-width: 600px) {
    margin-top: 3rem;
  }

  display: grid;
  & > div {
    margin: 1rem 0;
    @media screen and (min-width: 600px) {
      width: 30rem;
      margin: 1rem auto;
    }
  }
  button {
    width: fit-content;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
    font-weight: bold;
  }
}
.submitBtn {
  padding: 1rem;
  background-color: #136600;
  color: white;
  font-size: 1rem;
  font-weight: 600;
}
.success {
  color: #136600;
}
