.filterBarContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
  .filterItem {
    margin: 0.5rem;
    width: 8rem;
    & > div {
      color: #2c7b2c;
    }
    & * > ::placeholder {
      opacity: 1;
      color: #2c7b2c;
    }
  }
}
