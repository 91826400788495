.playerContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  @media screen and (min-width: 1201px) {
    width: 70%;
    padding-top: 40%;
    margin: auto;
  }
}
.player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
