.hostsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  p {
    font-size: 1.1rem;
  }
}
.host {
  display: grid;
  margin: 2rem auto 5rem;
  @media screen and (min-width: 600px) {
    max-width: 40rem;
  }
  @media screen and (min-width: 1201px) {
    max-width: 70rem;
    display: flex;
    align-items: center;
  }
}
.hostImg {
  max-width: 80%;
  margin: 1rem auto;
  @media screen and (min-width: 600px) {
    max-width: 50%;
  }
  @media screen and (min-width: 1201px) {
    max-height: 15rem;
    margin-right: 3rem;
  }
}
.hostBio {
  text-align: justify;
}
