.liveContainer {
  text-align: center;
  margin: 0 auto;
  p {
    font-size: 1.1rem;
  }
}
.liveText {
  color: #2c7b2c;
  margin-bottom: 2rem;
}
.livePlayer {
  width: unset !important;
  height: unset !important;
  max-width: 40rem;
  margin: 2rem auto;
}
.text {
  max-width: 40rem;
  text-align: justify;
  margin: 1rem auto !important;
}
